import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import { pictures } from '../csvSelectors'

const IndexPage = ({ data }) => {
  const pics = pictures(data.allPicturesCsv.edges)
  return (
    <Layout>
      <Gallery pictures={pics} showCategory />
    </Layout>
  )
}

export default IndexPage

export const pictureQuery = graphql`
  fragment Pictures on PicturesCsvConnection {
    edges {
      node {
        title
        type
        fields {
          slug
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 350
              quality: 80
              traceSVG: { background: "#41a7b3", color: "#1fe5bd" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export const query = graphql`
  query {
    allPicturesCsv {
      ...Pictures
    }
  }
`
